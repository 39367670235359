import React, { Component } from "react"
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { addVariantToCart } from '../modules/Product/products';
import { client } from '../helpers/shopify';

import VariantSelector from '../components/cart/VariantSelector'
import Layout from "../components/layout"

class ProductPageTemplate extends Component {
  state = {
    product: [],
    isLoading: true,
    selectedOptions: '',
  }
  
  componentDidMount() {
    this.fetchProductByHandle(this.props.pageContext.shopifyId)
  }

  fetchProductByHandle = (shopifyId) => {
    client.product.fetch(shopifyId).then((product) => {
      let defaultOptionValues = {};
      product.options.forEach((selector) => {
        defaultOptionValues[selector.name] = selector.values[0].value;
      });
      this.setState({
        product: product,
        selectedOptions: defaultOptionValues
      }, () => {
        this.setState({
          isLoading: false
        })
      })
    }).catch((err) => {
      console.log(err)
    })
  }


  handleOptionChange = (event) => {
    const target = event.target
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = client.product.helpers.variantForOptions(this.state.product, selectedOptions)
    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image
    });
  }

  handleQuantityChange = (event) => {
    this.setState({
      selectedVariantQuantity: event.target.value
    });
  }

  renderVariants = () => {
    let aOptionNames = [];
    // let variantImage = this.state.selectedVariantImage || this.state.product.images[0]
    let variant = this.state.selectedVariant || this.state.product.variants[0]
    let variantQuantity = this.state.selectedVariantQuantity || 1
    let variantSelectors = this.state.product.options.map((option) => {
      aOptionNames.push(option.name);
      return (
        <VariantSelector
          handleOptionChange={this.handleOptionChange}
          key={option.id.toString()}
          option={option}
        />
      );
    });
    return (
      <>
        {variantSelectors}
        <button className="Product__buy" onClick={() => this.props.addVariantToCart(variant.id, variantQuantity)}>Add to Cart</button>
      </>
    )
  }

  render() {
    const formatter = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 2
    })
    const currentProduct = this.props.data.shopifyProduct;
    return (
      <Layout>
        <div>
          <div className="shop-item-container" >
            <div className="shop-cell">
              <div className="shop-item-image">
                <div>
                  <Img fluid={currentProduct.images[0].localFile ? currentProduct.images[0].localFile.childImageSharp.fluid : ''} />
                  <div className="sold-out-centered">
                  </div>
                </div>
              </div>
            </div>
            <div className="shop-cell">
              <div className="shop-item-info">
                <div className="description">
                  <div dangerouslySetInnerHTML={{
                    __html: currentProduct.descriptionHtml
                  }} />
                </div>
                <div className="item-price">
                  {formatter.format(currentProduct.priceRange.minVariantPrice.amount)}
                </div>
                <div className="item-variant-selector">
                  {
                    this.state.isLoading ? (
                      null
                    ) : (
                      this.renderVariants()
                    )
                  }
                </div>
              </div>
            </div>
            <div className="shop-item-footer">
              <h1>{this.state.title}</h1>
            </div>
          </div>
        </div >
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  product: state._product,
});

const connectedProductPageTemplate = connect(mapStateToProps, { addVariantToCart })(ProductPageTemplate);

export const query = graphql`
  query currentProductQuery($id: String!) {
    shopifyProduct(id: { eq: $id }) {
      id
      shopifyId
      title
      handle
      descriptionHtml
      productType
      vendor
      priceRange {
        minVariantPrice {
          amount
        }
        maxVariantPrice {
          amount
        }
      }
      images {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      variants {
        id
        title
        price
      }
    }
  }
`;

export default connectedProductPageTemplate
